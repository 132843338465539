<template>
  <div class="page">
    <v-list
      v-if="isOperateColumn"
      isManySelect
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="postData"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="导入" @click="toImport()"></v-button>
        <v-button text="导出" @click="toExport()"></v-button>
        <v-button text="配置字段" @click="configureFields()"></v-button>
      </template>
      <template #searchSlot>
        <v-date
          label="年份"
          v-model="belongYear1"
          type="year"
          placeholder="选择年份"
          format="yyyy"
        >
        </v-date>
        <v-input
          label="纳税人识别号"
          v-model="postData.taxpayerRegistrationNumber"
          placeholder="请输入纳税人识别号"
        />
        <v-input
          label="公司名称"
          v-model="postData.companyName"
          placeholder="请输入公司名称"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('businessRegistrationNumber') != -1 ||
            showAll
          "
          label="工商注册号"
          v-model="postData.businessRegistrationNumber"
          placeholder="请输入工商注册号"
        />
        <v-input
          v-if="lastSearchOption.indexOf('tradeType') != -1 || showAll"
          label="产业类型"
          v-model="postData.tradeType"
          placeholder="请输入产业类型"
        />
        <v-date
          label="注册/迁入时间"
          type="date"
          v-model="registrationTime1"
          placeholder="请选择注册/迁入时间"
          format="yyyy-MM-dd"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('bureauRegisteredIndustryType') != -1 ||
            showAll
          "
          label="工商注册行业"
          v-model="postData.bureauRegisteredIndustryType"
          placeholder="请输入工商注册行业"
        />
        <v-input
          v-if="lastSearchOption.indexOf('businessScope') != -1 || showAll"
          label="经营内容"
          v-model="postData.businessScope"
          placeholder="请输入经营内容"
        />
        <v-input
          v-if="lastSearchOption.indexOf('businessAddress') != -1 || showAll"
          label="经营地址"
          v-model="postData.businessAddress"
          placeholder="请输入经营地址"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('registrationAddress') != -1 || showAll
          "
          label="注册地址"
          v-model="postData.registrationAddress"
          placeholder="请输入注册地址"
        />
        <v-input
          v-if="lastSearchOption.indexOf('belongStreet') != -1 || showAll"
          label="所属区域"
          v-model="postData.belongStreet"
          placeholder="请输入所属区域"
        />
        <v-select
          clearable
          :options="communityList"
          v-model="postData.belongCommunity"
          label="所属社区"
          placeholder="请选择所属社区"
        />
        <v-select
          v-if="lastSearchOption.indexOf('belongBuilding') != -1 || showAll"
          clearable
          :options="buildingList"
          v-model="postData.belongBuilding"
          label="所属楼宇"
          placeholder="请选择所属楼宇"
        />
        <v-input
          v-if="lastSearchOption.indexOf('belongFloor') != -1 || showAll"
          label="所属楼层"
          v-model="postData.belongFloor"
          placeholder="请输入所属楼层"
        />
        <v-input
          v-if="lastSearchOption.indexOf('belongGroup') != -1 || showAll"
          label="所属集团"
          v-model="postData.belongGroup"
          placeholder="请输入所属集团"
        />
        <v-input
          v-if="lastSearchOption.indexOf('leitungsorgan') != -1 || showAll"
          label="企业归口"
          v-model="postData.leitungsorgan"
          placeholder="请输入企业归口"
        />
        <v-input
          v-if="lastSearchOption.indexOf('economicsType') != -1 || showAll"
          label="经济类型"
          v-model="postData.economicsType"
          placeholder="请输入经济类型"
        />
        <v-input
          v-if="lastSearchOption.indexOf('industryType') != -1 || showAll"
          label="行业类型"
          v-model="postData.industryType"
          placeholder="请输入行业类型"
        />
        <v-input
          v-if="lastSearchOption.indexOf('threeIndustry') != -1 || showAll"
          label="三产划分"
          v-model="postData.threeIndustry"
          placeholder="请输入三产划分"
        />
        <v-input
          v-if="lastSearchOption.indexOf('businessStatus') != -1 || showAll"
          label="企业状态"
          v-model="postData.businessStatus"
          placeholder="请输入企业状态"
        />
        <v-select
          v-if="lastSearchOption.indexOf('isAreaRegistration') != -1 || showAll"
          clearable
          :options="yesOrNoList"
          v-model="postData.isAreaRegistration"
          label="是否区内注册"
          placeholder="请选择是否区内注册"
        />
        <v-input
          v-if="lastSearchOption.indexOf('placePropertyRight') != -1 || showAll"
          label="场地产权"
          v-model="postData.placePropertyRight"
          placeholder="请输入场地产权"
        />
        <v-input
          v-if="lastSearchOption.indexOf('legalPerson') != -1 || showAll"
          label="法人代表"
          v-model="postData.legalPerson"
          placeholder="请输入法人代表姓名"
        />
        <v-input
          v-if="lastSearchOption.indexOf('legalPersonMobile') != -1 || showAll"
          label="法人手机号码"
          v-model="postData.legalPersonMobile"
          placeholder="请输入法人手机号码"
        />
        <v-input
          v-if="lastSearchOption.indexOf('financialStaff') != -1 || showAll"
          label="财务人员"
          v-model="postData.financialStaff"
          placeholder="请输入财务人员姓名"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('financialStaffMobile') != -1 || showAll
          "
          label="财务人员手机号码"
          v-model="postData.financialStaffMobile"
          placeholder="请输入财务人员手机号码"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('administrativeStaff') != -1 || showAll
          "
          label="行政人员"
          v-model="postData.administrativeStaff"
          placeholder="请输入行政人员姓名"
        />
        <v-input
          v-if="
            lastSearchOption.indexOf('administrativeStaffMobile') != -1 ||
            showAll
          "
          label="行政人员手机号码"
          v-model="postData.administrativeStaffMobile"
          placeholder="请输入行政人员手机号码"
        />
        <div
          v-if="lastSearchOption.indexOf('employeeNums') != -1 || showAll"
          class="box"
        >
          <v-input
            label="职工人数"
            v-model="postData.employeeNumsMin"
            placeholder="请输入职工最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.employeeNumsMax"
            placeholder="请输入职工最高人数"
          />
        </div>
        <div
          v-if="lastSearchOption.indexOf('partyMemberNums') != -1 || showAll"
          class="box"
        >
          <v-input
            label="党员人数"
            v-model="postData.partyMemberNumsMin"
            placeholder="请输入党员最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.partyMemberNumsMax"
            placeholder="请输入党员最高人数"
          />
        </div>
        <div
          v-if="lastSearchOption.indexOf('researchNums') != -1 || showAll"
          class="box"
        >
          <v-input
            label="研发人数"
            v-model="postData.researchNumsMin"
            placeholder="请输入研发最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.researchNumsMax"
            placeholder="请输入研发最高人数"
          />
        </div>
        <div
          v-if="lastSearchOption.indexOf('juniorCollegeNums') != -1 || showAll"
          class="box"
        >
          <v-input
            label="大专以上人数"
            v-model="postData.juniorCollegeNumsMin"
            placeholder="请输入大专以上最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.juniorCollegeNumsMax"
            placeholder="请输入大专以上最高人数"
          />
        </div>
        <div
          v-if="
            lastSearchOption.indexOf('overseasUndergraduateNums') != -1 ||
            showAll
          "
          class="box"
        >
          <v-input
            label="海外本科人数"
            v-model="postData.overseasUndergraduateNumsMin"
            placeholder="请输入海外本科最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.overseasUndergraduateNumsMax"
            placeholder="请输入海外本科最高人数"
          />
        </div>
        <div
          v-if="lastSearchOption.indexOf('inlandDoctorNums') != -1 || showAll"
          class="box"
        >
          <v-input
            label="国内博士人数"
            v-model="postData.inlandDoctorNumsMin"
            placeholder="请输入国内博士最低人数"
          />
          <div class="line">-</div>
          <v-input
            v-model="postData.inlandDoctorNumsMax"
            placeholder="请输入国内博士最高人数"
          />
        </div>
        <v-input
          v-if="lastSearchOption.indexOf('dispatchStatus') != -1 || showAll"
          label="分派状态"
          v-model="postData.dispatchStatus"
          placeholder="请输入分派状态"
        />
        <v-select
          v-if="lastSearchOption.indexOf('isLeadType') != -1 || showAll"
          clearable
          :options="yesOrNoList"
          v-model="postData.isLeadType"
          label="是否引导型企业"
          placeholder="请选择是否引导型企业"
        />
        <v-button :text="showAllTxt" @click="showMoreSearchOption()"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="发送短信"
          @click="batchSend(scope.selectedData)"
          :disabled="scope.selectedData.data.length === 0"
        ></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <!-- 配置字段弹框 -->
    <v-dialog
      title="配置字段"
      v-model="dialogConfigureFields"
      width="50%"
      sureTxt="保存"
      @confirm="submitConfigureFields"
    >
      <el-form label-position="right">
        <el-form-item>
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in allSearchOption"
              :key="item.value"
              :index="index"
              type="border"
              :label="item.label"
              :disabled="item.disabled"
            />
          </div>
        </el-form-item>
      </el-form>
    </v-dialog>
    <!-- 发送短信弹框 -->
    <v-dialog
      title="发送短信"
      v-model="dialogSendSms"
      sureTxt="发送"
      @confirm="submitSendSms"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="发送人类型：">
          <v-select clearable :options="sendTypeList" v-model="sendType" />
        </el-form-item>
        <el-form-item label="发送对象：">
          <v-input disabled v-model="sendNum" />
        </el-form-item>
        <el-form-item label="短信内容：">
          <v-input
            placeholder="请输入短信内容"
            type="textarea"
            rows="5"
            v-model="smsContent"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import { createHTMLVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "buildingManagement",
  data() {
    return {
      buildingList: [],
      communityList: [
        {
          value: "明珠社区",
          label: "明珠社区",
        },
      ],
      yesOrNoList: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],

      showAll: false,
      showAllTxt: "更多",

      isOperateColumn: false,

      dialogConfigureFields: false,

      dialogSendSms: false,
      sendType: null,
      sendNum: null,
      smsContent: null,
      sendTypeList: [
        {
          value: 1,
          label: "法务人员",
        },
        {
          value: 2,
          label: "财务人员",
        },
        {
          value: 3,
          label: "行政人员",
        },
      ],

      belongYear1: null,
      registrationTime1: null,
      postData: {
        belongYear: null,
        taxpayerRegistrationNumber: null,
        companyName: null,
        businessRegistrationNumber: null,
        tradeType: null,
        registrationTime: null,
        bureauRegisteredIndustryType: null,
        businessScope: null,
        businessAddress: null,
        registrationAddress: null,
        belongStreet: null,
        belongCommunity: "明珠社区",
        belongBuilding: null,
        belongFloor: null,
        belongGroup: null,
        leitungsorgan: null,
        economicsType: null,
        industryType: null,
        threeIndustry: null,
        businessStatus: null,
        isAreaRegistration: null,
        placePropertyRight: null,
        legalPerson: null,
        legalPersonMobile: null,
        financialStaff: null,
        financialStaffMobile: null,
        administrativeStaff: null,
        administrativeStaffMobile: null,
        employeeNumsMin: null,
        employeeNumsMax: null,
        partyMemberNumsMin: null,
        partyMemberNumsMax: null,
        researchNumsMin: null,
        researchNumsMax: null,
        juniorCollegeNumsMin: null,
        juniorCollegeNumsMax: null,
        overseasUndergraduateNumsMin: null,
        overseasUndergraduateNumsMax: null,
        inlandDoctorNumsMin: null,
        inlandDoctorNumsMax: null,
        dispatchStatus: null,
        isLeadType: null,
      },

      tableUrl: getBusinessInfosUrl,
      headers: [
        {
          prop: "taxpayerRegistrationNumber",
          label: "纳税人识别号",
          show: false,
        },
        {
          prop: "companyName",
          label: "公司名称",
          show: false,
        },
        {
          prop: "businessRegistrationNumber",
          label: "工商注册号",
          show: false,
        },
        {
          prop: "tradeType",
          label: "产业类型",
          show: false,
        },
        {
          prop: "registrationTime",
          label: "注册/迁入时间",
          show: false,
        },
        {
          prop: "bureauRegisteredIndustryType",
          label: "工商注册行业",
          show: false,
        },
        {
          prop: "businessScope",
          label: "经营内容",
          show: false,
        },
        {
          prop: "businessAddress",
          label: "经营地址",
          show: false,
        },
        {
          prop: "registrationAddress",
          label: "注册地址",
          show: false,
        },
        {
          prop: "belongStreet",
          label: "所属区域",
          show: false,
        },
        {
          prop: "belongCommunity",
          label: "所属社区",
          show: false,
        },
        {
          prop: "belongBuilding",
          label: "所属楼宇",
          show: false,
        },
        {
          prop: "belongFloor",
          label: "所属楼层",
          show: false,
        },
        {
          prop: "belongGroup",
          label: "所属集团",
          show: false,
        },
        {
          prop: "leitungsorgan",
          label: "企业归口",
          show: false,
        },
        {
          prop: "economicsType",
          label: "经济类型",
          show: false,
        },
        {
          prop: "industryType",
          label: "行业类型",
          show: false,
        },
        {
          prop: "threeIndustry",
          label: "三产划分",
          show: false,
        },
        {
          prop: "businessStatus",
          label: "企业状态",
          show: false,
        },
        {
          prop: "revokeTime",
          label: "吊销日期",
          show: false,
        },
        {
          prop: "logoutTime",
          label: "注销日期",
          show: false,
        },
        {
          prop: "isAreaRegistration",
          label: "是否区内注册",
          show: false,
          formatter: (row, prop) => {
            if (row.isAreaRegistration == 1) {
              return "是";
            } else {
              return "否";
            }
          },
        },
        {
          prop: "placePropertyRight",
          label: "场地产权",
          show: false,
        },
        {
          prop: "legalPerson",
          label: "法人代表",
          show: false,
        },
        {
          prop: "legalPersonMobile",
          label: "法人手机号码",
          show: false,
        },
        {
          prop: "financialStaff",
          label: "财务人员",
          show: false,
        },
        {
          prop: "financialStaffMobile",
          label: "财务人员手机号码",
          show: false,
        },
        {
          prop: "administrativeStaff",
          label: "行政人员",
          show: false,
        },
        {
          prop: "administrativeStaffMobile",
          label: "行政人员手机号码",
          show: false,
        },
        {
          prop: "employeeNums",
          label: "职工人数",
          show: false,
        },
        {
          prop: "partyMemberNums",
          label: "党员人数",
          show: false,
        },
        {
          prop: "researchNums",
          label: "研发人数",
          show: false,
        },
        {
          prop: "juniorCollegeNums",
          label: "大专以上人数",
          show: false,
        },
        {
          prop: "overseasUndergraduateNums",
          label: "海外本科人数",
          show: false,
        },
        {
          prop: "inlandDoctorNums",
          label: "国内博士人数",
          show: false,
        },
        {
          prop: "dispatchStatus",
          label: "分派状态",
          show: false,
        },
        {
          prop: "isLeadType",
          label: "是否引导型企业",
          show: false,
        },
        {
          prop: "totalOperationProceeds",
          label: "营业总收入",
          show: false,
          formatter: (row, prop) => {
            let oBtn = `<span>${row.totalOperationProceeds}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                this.$router.push({
                  name: "businessPage",
                  query: {
                    taxpayerRegistrationNumber: row.taxpayerRegistrationNumber,
                  },
                });
              },
            });
          },
        },
        {
          prop: "totalRevenue",
          label: "总税收",
          show: false,
          formatter: (row, prop) => {
            let oBtn = `<span>${row.totalRevenue}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                console.log(2123);
                this.$router.push({
                  name: "taxSourcePage",
                  query: {
                    taxpayerRegistrationNumber: row.taxpayerRegistrationNumber,
                  },
                });
              },
            });
          },
        },
        {
          prop: "totalVisitings",
          label: "被走访情况",
          show: false,
          formatter: (row, prop) => {
            let oBtn = `<span>${row.totalVisitings}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                this.$router.push({
                  name: "visitorsPage",
                  query: {
                    taxpayerRegistrationNumber: row.taxpayerRegistrationNumber,
                  },
                });
              },
            });
          },
        },
      ],

      allSearchOption: [],
      lastSearchOption: [],
      businessNumbers: [],
    };
  },
  watch: {
    belongYear1: {
      handler(nVal, oVal) {
        this.postData.belongYear = nVal.getFullYear();
      },
    },
    registrationTime1: {
      handler(nVal, oVal) {
        let year = nVal.getFullYear();
        let month =
          nVal.getMonth() + 1 < 10
            ? "0" + (nVal.getMonth() + 1)
            : nVal.getMonth() + 1;
        let day = nVal.getDate() < 10 ? "0" + nVal.getDate() : nVal.getDate();
        this.postData.registrationTime = `${year}-${month}-${day}`;
      },
    },
  },
  created() {
    this.getBuildingList();
    // this.postData.belongYear = new Date().getFullYear() - 1;
    this.getAllSearchOption();
  },
  async mounted() {
    await this.getLastSearchOption();
  },
  methods: {
    // 获取楼宇列表
    getBuildingList() {
      this.$axios.get(`${getBuildingInfoUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.value = item.buildingName;
            item.label = item.buildingName;
          });
          this.buildingList = res.data;
        }
      });
    },
    // 发送短信-发送
    submitSendSms() {
      if (!this.sendType || this.sendType.length == 0) {
        this.$message.error("请选择发送人类型！");
        return;
      }
      if (!this.smsContent || this.smsContent.length == 0) {
        this.$message.error("请输入短信内容！");
        return;
      }
      let params = {
        businessNumbers: this.businessNumbers.join(","),
        type: this.sendType,
        content: this.smsContent,
      };
      this.$axios
        .post(`${sendSmsUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("发送成功");
            this.dialogSendSms = false;
          }
        });
    },
    // 发送短信
    batchSend(list) {
      const { data, ids } = list;
      this.businessNumbers = [];
      data.forEach((item) => {
        this.businessNumbers.push(item.taxpayerRegistrationNumber);
      });
      console.log(this.businessNumbers.join(","));
      this.dialogSendSms = true;
      this.sendNum = this.businessNumbers.length + "人";
    },
    // 更多
    showMoreSearchOption() {
      if (this.showAllTxt == "更多") {
        this.showAll = true;
        this.showAllTxt = "收起";
      } else if (this.showAllTxt == "收起") {
        this.showAll = false;
        this.showAllTxt = "更多";
      }
    },
    // 导入
    toImport() {
      this.$router.push({
        name: "importBuilding",
      });
    },
    // 导出
    toExport() {
      let params = {
        form: this.postData,
      };
      this.$axios
        .post(
          `/gateway/hc-arch/buildingRegulate/exportBusinessInfo?returnParam=${this.lastSearchOption}`,
          params,
          {
            responseType: "blob",
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
    // 配置字段
    configureFields() {
      this.getLastSearchOption();
      this.dialogConfigureFields = true;
    },
    // 配置字段-保存
    async submitConfigureFields() {
      let list = [];
      this.allSearchOption.forEach((item) => {
        if (item.checked) {
          list.push(item.value);
        }
      });
      let params = {
        words: list.join(","),
        userId: this.$store.state.app.userInfo.userId,
      };
      let res = await this.$axios.post(
        `${saveLastWordsUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.isOperateColumn = false;
        await this.getLastSearchOption();
        this.$message.success(res.msg);
        this.dialogConfigureFields = false;
      }
    },
    // 操作-删除
    remove(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            taxpayerRegistrationNumber: item.taxpayerRegistrationNumber,
          };
          this.$axios
            .post(
              `/gateway/hc-arch/buildingRegulate/deleteBusinessInfo`,
              this.$qs.stringify(params)
            )
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    // 操作-编辑
    edit(item) {
      this.$router.push({
        name: "buildingManagementForm",
        query: { id: item.taxpayerRegistrationNumber },
      });
    },
    // 获取历史配置字段
    async getLastSearchOption() {
      let params = {
        userId: this.$store.state.app.userInfo.userId,
      };
      let res = await this.$axios.get(`${getLastWordsUrl}`, { params: params });
      if (res.code === 200) {
        let list = [
          "belongYear",
          "taxpayerRegistrationNumber",
          "companyName",
          "tradeType",
          "partyMemberNums",
          "belongCommunity",
          "belongBuilding",
          "totalOperationProceeds",
          "totalRevenue",
          "totalVisitings",
          "belongFloor",
        ];
        res.data.split(",").forEach((item) => {
          if (list.indexOf(item) == -1) {
            list.push(item);
          }
        });
        this.lastSearchOption = list;
        console.log(this.lastSearchOption);
        this.lastSearchOption.forEach((item, index) => {
          this.allSearchOption.forEach((oitem, oindex) => {
            if (oitem.value == item) {
              oitem.checked = true;
            }
            if (
              oitem.value == "taxpayerRegistrationNumber" ||
              oitem.value == "companyName" ||
              oitem.value == "totalOperationProceeds" ||
              oitem.value == "totalRevenue" ||
              oitem.value == "totalVisitings" ||
              oitem.value == "belongYear" ||
              oitem.value == "partyMemberNums" ||
              oitem.value == "revokeTime" ||
              oitem.value == "tradeType" ||
              oitem.value == "belongCommunity" ||
              oitem.value == "belongBuilding" ||
              oitem.value == "registrationTime" ||
              oitem.value == "belongFloor" ||
              oitem.value == "logoutTime"
            ) {
              oitem.checked = true;
              oitem.disabled = true;
            }
          });
        });
        this.headers.forEach((item) => {
          if (
            res.data.indexOf(item.prop) != -1 ||
            item.prop == "taxpayerRegistrationNumber" ||
            item.prop == "companyName" ||
            item.prop == "totalOperationProceeds" ||
            item.prop == "totalRevenue" ||
            item.prop == "tradeType" ||
            item.prop == "belongCommunity" ||
            item.prop == "belongBuilding" ||
            item.prop == "belongFloor" ||
            item.prop == "registrationTime" ||
            item.prop == "legalPerson" ||
            item.prop == "legalPersonMobile" ||
            item.prop == "totalVisitings"
          ) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
        this.isOperateColumn = true;
      }
    },
    // 获取所有可配置字段
    getAllSearchOption() {
      this.$axios.get(`${getSearchWordsUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.checked = false;
            item.value = item.code;
            item.label = item.content;
            item.disabled = false;
          });
          this.allSearchOption = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
    flex-flow: row wrap;
  }
  .box {
    display: flex;
    align-items: center;
    .line {
      margin-right: 10px;
      margin-left: -10px;
    }
  }
}
</style>
